import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DefaultSwitch from "../../components/input/DefaultSwitch";
import PricingListItem from "./pricingListItem";
import Slider from "react-slick";
import PricingLeftArrow from "./PricingLeftArrow";
import PricingRightArrow from "./PricingRightArrow";
import "../../routes/styles/pricingList.css";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadRZP } from "utils/helpers/loadRZP";

const PricingListWrapper = ({ updatePack }) => {
  const [monthlyBilling, setMonthlyBilling] = React.useState(false); // its quarterly now
  const sliderRef = React.useRef(null);
  const [params] = useSearchParams();
  const userType = params.get("userType") || "PHOTOGRAPHER";

  const plans = useSelector((state) => state.subscription?.publicPlans);
  const { isUserInIndia } = useSelector((state) => state.globals);

  const pricingListData = plans
    ?.filter((plan) =>
      monthlyBilling ? plan?.period === "monthly" : plan?.period === "yearly"
    )
    ?.filter((plan) => plan?.billing_amount > 100)
    ?.map((plan) => {
      let evsl = ""; // extended video storage limit
      const features = plan?.features
        ?.filter((feature) => {
          if (feature?.enabled && feature?.name == "extended_video_storage") {
            evsl = feature?.limit;
            return false;
          } else if (feature?.enabled && feature?.name !== "web_mode") {
            return true;
          }
          return false;
        })
        .map((feature) => {
          if (feature?.name === "extended_photo_storage" && evsl)
            return (
              (feature?.extras?.featureText || "") + " and " + evsl + " videos"
            );
          return feature?.extras?.featureText || "";
        });

      return {
        plan_id: plan?.plan_id,
        price: isUserInIndia ? plan?.billing_amount : plan?.billing_amount_USD,
        type: monthlyBilling ? "month" : "year",
        period: plan?.period,
        packageName: plan?.displayText || "",
        packageDesciption: plan?.displaySubText || "",
        packageIcon: "/assets/images/icons/premium.png",
        userType: plan?.userType,
        features,
        // navigateToUrl: () => "/",
      };
    })
    .sort((a, b) => a.price - b.price);

  React.useEffect(() => {
    if (updatePack)
      updatePack({
        yearly: !monthlyBilling,
        details:
          pricingListData?.length > 0
            ? pricingListData[
                sliderRef.current?.innerSlider?.state?.currentSlide ?? 1
              ]
            : [],
      });
  }, [monthlyBilling]);

  React.useEffect(() => {
    loadRZP();
  }, []);

  return (
    <>
      <div>
        <div className="text-center pricing-header">
          <h1>GET CONNECTED</h1>
          <p>Choose the plan that&apos;s right for you</p>
          <img
            className="under-line"
            src="/assets/images/landing/yellow-divider.png"
            alt="Yellow-divider"
          />
        </div>

        <div className="pricing-wrapper">
          <div className="pricing-toggler">
            <div className="pricing-control-wrapper justify-content-center">
              <div className="check-box position-relative">
                <label className={!monthlyBilling ? "dim" : ""}>
                  Bill Quarterly
                </label>
                <DefaultSwitch
                  onChange={() => setMonthlyBilling(!monthlyBilling)}
                  isChecked={!monthlyBilling}
                />
                <label className={monthlyBilling ? "dim" : ""}>
                  Bill Annually
                </label>
                <img
                  src="/assets/icons/30-percent.png"
                  alt="30% off"
                  className="discount-badge"
                />
              </div>
            </div>
          </div>
          <div className="d-none d-md-flex justify-content-center align-items-center font-15 flex-column">
            <p className="m-0 mt-1 mb-2">
              All plans have&nbsp;<b>Unlimited</b>
            </p>
            <div className="font-base color-primary d-flex">
              <p className="px-lg-3 text-center m-0">
                <img
                  className="me-1 align-baseline hw-15px"
                  src="/assets/icons/tick-blue.png"
                  alt="tick"
                />
                FACIAL RECOGNITION
              </p>
              <p className="px-lg-3 text-center m-0">
                <img
                  className="me-1 align-baseline hw-15px"
                  src="/assets/icons/tick-blue.png"
                  alt="tick"
                />
                USER REGISTRATION
              </p>
              <p className="px-lg-3 text-center m-0">
                <img
                  className="me-1 align-baseline hw-15px"
                  src="/assets/icons/tick-blue.png"
                  alt="tick"
                />
                ALBUM CREATION
              </p>
              <p className="px-lg-3 text-center m-0">
                <img
                  className="me-1 align-baseline hw-15px"
                  src="/assets/icons/tick-blue.png"
                  alt="tick"
                />
                24x7 SUPPORT ON WHATSAPP
              </p>
            </div>
          </div>
          <div className="d-flex d-md-none justify-content-center unlimted-fr">
            All plans have unlimited facial recognition
          </div>
          {(pricingListData?.length > 0 && (
            <Slider
              {...{
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                initialSlide: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                swipe: false,
                arrows: true,
                nextArrow: <PricingRightArrow />,
                prevArrow: <PricingLeftArrow />,
                rows: 1,
                ref: sliderRef,
                onInit: () => {
                  if (updatePack)
                    updatePack({ plan: 1, details: pricingListData[1] });
                },
                afterChange: (e) => {
                  if (updatePack)
                    updatePack({ plan: e, details: pricingListData[e] });
                },
                responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      swipe: true,
                    },
                  },
                  {
                    breakpoint: 993,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipe: true,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipe: true,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipe: true,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1,
                      swipe: true,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipe: true,
                    },
                  },
                ],
                className: `pricing-item-wrapper ${
                  userType === "USER" ? "user-plan" : ""
                }`,
              }}
            >
              {pricingListData.map((item, index) => (
                <PricingListItem key={index} item={item} />
              ))}
            </Slider>
          )) || (
            <div className="text-center py-5 font-bold font-20">
              No plans available in this category.
            </div>
          )}
          <div className="free-plan-wrapper">
            <Link to="/auth/login">
              Continue with free plan{" "}
              <img
                style={{ marginLeft: "10px" }}
                src="/assets/images/icons/Vector104.svg"
                alt=""
              />
            </Link>
            <br />
            <small>Store upto 1000 Photos</small>
          </div>
        </div>

        <div className="contact-us">
          <span className="">For more Storage or Features</span>
          <Link
            to="/helpdesk"
            target="_blank"
            // onClick={() => window.scrollTo(0, 0)}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
};

export default PricingListWrapper;
