import { createSlice } from "@reduxjs/toolkit";
import PLANS from "constants/plans";
import { getUserSubscriptionData } from "utils/helpers/localstorage";

const INITIAL_STATE = {
  allPlans: [],
  featureUpgrades: {},
  activePlan: {},
  plansLoading: false,
  loading: false,
  orderDetails: {},
  paymentLink: {},
  billingInfo: {},
  publicPlans: [],
};

const slice = createSlice({
  name: "subscription",
  initialState: INITIAL_STATE,
  reducers: {
    setAllPlans(state, action) {
      let allPlans = action.payload;

      const { plan_id } = getUserSubscriptionData() || {};
      const userOnFreePlan =
        PLANS.SHOW_1000_PHOTO_STORAGE_PLANS.includes(plan_id);

      // if user is not on lifetime free plans, then remove the 'Basic Lite' plan from the list
      if (!userOnFreePlan) {
        allPlans = allPlans.filter((plan) => plan.displayText !== "Basic Lite");
      }

      return {
        ...state,
        allPlans,
      };
    },
    setFeatureUpgrades(state, action) {
      return {
        ...state,
        featureUpgrades: action.payload,
      };
    },
    setPlansLoading(state, action) {
      return {
        ...state,
        plansLoading: action.payload,
      };
    },
    setActivePlan(state, action) {
      return {
        ...state,
        activePlan: action?.payload || {},
      };
    },
    setPublicPlans(state, action) {
      let publicPlans = action.payload;

      const { plan_id } = getUserSubscriptionData() || {};
      const userOnFreePlan =
        PLANS.SHOW_1000_PHOTO_STORAGE_PLANS.includes(plan_id);

      // if user is not on lifetime free plans, then remove the 'Basic Lite' plan from the list
      if (!userOnFreePlan) {
        publicPlans = publicPlans.filter(
          (plan) => plan.displayText !== "Basic Lite"
        );
      }

      return {
        ...state,
        publicPlans,
      };
    },
    setOrderDetails(state, action) {
      return {
        ...state,
        orderDetails: action.payload,
      };
    },
    clearOrderDetails(state) {
      return {
        ...state,
        orderDetails: {},
      };
    },
    setPaymentLink(state, action) {
      return {
        ...state,
        paymentLink: action.payload,
      };
    },
    setBillingInfo(state, action) {
      return {
        ...state,
        billingInfo: action.payload,
      };
    },
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const {
  setAllPlans,
  setFeatureUpgrades,
  setPlansLoading,
  setActivePlan,
  setPublicPlans,
  setOrderDetails,
  clearOrderDetails,
  setPaymentLink,
  setBillingInfo,
  setLoading,
} = slice.actions;

export default slice.reducer;
